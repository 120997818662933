<!-- eslint-disable vue/no-template-key -->
<template>
  <!-- BEGIN: Content-->
  <div>
    <div
      v-if="!loading"
      id="offcanvasRetrieveApplicant"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-90"
      tabindex="-1"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      aria-labelledby="offcanvasRetrieveApplicantLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasViewFormLabel"
          class="offcanvas-title"
        >
          <small>{{ convoAux ? convoAux.title : convo.title }}</small><br><strong>{{ selected.full_name }}</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="row viewform__evaluation">
          <div class="col-sm-2 viewform__average">
            <p class="mb-3">
              GLOBAL EVAL AVG <span class="text-warning"><i data-feather="circle" /></span><br><strong>{{ selected.avg_grade }}</strong>
            </p>
            <p>NORMALIZED <span class="text-warning"><i data-feather="circle" /></span><br><strong>{{ selected.avg_normalized }}</strong></p>
            <hr class="mt-2 mb-2">
            <p class="mb-0 fw-bold text-dark">
              FEEDBACK APPROVED
            </p>
            <div class="form-check">
              <input
                id="flexCheckDefault3"
                class="form-check-input"
                type="checkbox"
                :checked="selected.status ? selected.status.feedback_approved : false"
                value="1"
                @click="rateApplicant({
                  feedback_approved: $event.target.checked,
                })"
              >
              <label
                class="form-check-label d-block"
                for="flexCheckDefault3"
              >Yes</label>
            </div>
          </div>
          <div class="col-sm-6">
            <label
              for=""
              class="form-label"
            >Move candidate to:</label>
            <select
              v-model="status"
              class="form-select select2"
            >
              <option value="3">
                Not eligible
              </option>
              <option value="9">
                Blocked for next year
              </option>
              <option value="2">
                Round 0
              </option>
              <option
                value="4"
                selected
              >
                1st round
              </option>
              <option value="5">
                2nd round
              </option>
              <option value="6">
                Ranked
              </option>
            </select>
          </div>
          <div class="col-sm-1">
            <a
              class="btn btn-icon btn-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Change the status of the applicant"
              @click="updateStatus"
            ><i data-feather="arrow-right" /></a>
          </div>
          <div
            v-if="selected && selected.evaluators && selected.evaluators.find(e => e.pivot.feedback_editor == true)"
            class="col-sm-12 viewform__average"
          >
            <p class="mb-0 mt-1 fw-bold text-dark">
              FEEDBACK MESSAGE (This is the final message the applicant will recieve)
            </p>
            <textarea
              v-model="selected.status.feedback_message"
              class="form-control"
              style="min-height:143px;"
              @change="rateApplicant({
                feedback_message: selected.status.feedback_message,
              })"
            />
          </div>
        </div>
        <hr>
        <h3
          v-if="convoAux ? convoAux.current_access && convoAux.current_access.type >= 5 : (convo.current_access && convo.current_access.type >= 5)"
          class="mb-2"
        >
          Round 0 evaluation
        </h3>
        <template v-if="selected.evaluators">
          <p
            v-for="evaluator in selected.evaluators.filter(e => e.pivot.competent != null)"
            :key="`${selected.user_id}-2-${evaluator.id}`"
          >
            <i
              data-feather="check"
              :class="{'d-none': evaluator.pivot.competent === false || evaluator.pivot.competent === null}"
              class="text-success me-1"
            />
            <i
              :class="{'d-none': evaluator.pivot.competent || evaluator.pivot.competent === null}"
              data-feather="x"
              class="text-danger me-1"
            />
            <i
              :class="{'d-none': evaluator.pivot.competent !== null}"
              data-feather="circle"
              class="text-secondary me-1"
            />
            {{ evaluator.name }} {{ evaluator.feedback_editor ? '- Feedback editor' : '' }}
            <br>
            <small v-if="selected.retrieve && selected.retrieve.evaluator_id === evaluator.id">
              <strong>Retrieve: </strong>
              {{ selected.retrieve.reason }}
            </small>
          </p>
        </template>
        <hr>
        <div class="viewform__actions d-flex justify-content-between">
          <div
            v-if="selected.evaluators"
            class="viewform__actions--evaluators"
          >
            <p><strong>{{ selected.evaluators.filter(e => e.pivot.avg_grade != null).length }}</strong>/{{ selected.evaluators.length }} Evaluators</p>
          </div>
        </div>
        <div
          v-for="evaluator in selected.evaluators"
          :key="`${selected.user_id}-${evaluator.id}`"
          class="card viewform__card-evaluator"
        >
          <div
            v-b-toggle="`collapseEvaluator-${evaluator.id}`"
            class="card-header"
          >
            <h3 class="cart-title d-flex justify-content-between w-90">
              <a data-action="collapse">
                <i
                  data-feather="circle"
                  :class="{
                    'text-success': evaluator.pivot.avg_grade,
                    'text-warning': !evaluator.pivot.avg_grade,
                  }"
                  class="text-success"
                /> {{ evaluator.name }}
                <span
                  v-if="evaluator.pivot.feedback_editor"
                  class="badge bg-light-primary"
                >Feedback editor</span>
                <span
                  v-if="evaluator.moderator"
                  class="badge bg-light-secondary"
                >MODERATOR</span>
                <span
                  v-if="evaluator.pivot.interest_conflict"
                  class="badge bg-light-warning"
                >Conflict of interest</span></a>
              <span
                v-if="evaluator.pivot && evaluator.pivot.avg_grade"
                class="dis-ib me-2"
              >
                <strong>Mark: {{ evaluator.pivot.avg_grade }}</strong> <span
                  v-if="evaluator.call_avg"
                  class="text-muted"
                >(Global average: {{ evaluator.call_avg ? evaluator.call_avg : '-' }})</span>
              </span>
            </h3>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li><a data-action="collapse"><i data-feather="chevron-down" /></a></li>
              </ul>
            </div>
          </div>
          <b-collapse
            :id="`collapseEvaluator-${evaluator.id}`"
          >
            <div class="card-content ">
              <div class="card-body">
                <div>
                  <p><strong>Strengths</strong></p>
                  <p v-html="evaluator.pivot.stregths" />
                </div>
                <div>
                  <p><strong>Weaknesses</strong></p>
                  <p v-html="evaluator.pivot.weaknesses" />
                </div>
                <div>
                  <p><strong>General comments</strong></p>
                  <p v-html="evaluator.pivot.general_comments" />
                </div>
                <div v-if="evaluator.pivot.feedback_editor">
                  <p><strong>Feedback message</strong></p>
                  <p v-html="evaluator.pivot.feedback_message" />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>

  <!-- END: Content-->
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    selected: { type: Object, required: true },
    convoAux: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      loading: false,
      status: 4,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/formEvaluation',
      convo: 'convo/convo',
    }),
  },
  watch: {
    async selected() {
      this.loading = true
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)

      if (!this.selected.evaluators && this.selected) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convoAux ? this.convoAux.id : this.convo.id}/get-applicants/0`, { id: this.convoAux ? this.convoAux.id : this.convo.id, status: { value: 0 }, candidate: this.selected.user_id })
        if (resp.data && resp.data.data[0]) {
          this.selected = resp.data.data[0]
        }
      }

      if (this.selected && this.selected.status && !this.selected.status.feedback_message && this.selected.evaluators && this.selected.evaluators.find(e => e.pivot.feedback_editor == true)) {
        this.selected.status.feedback_message = this.selected.evaluators.find(e => e.pivot.feedback_editor == true).pivot.feedback_message
      }
      this.loading = false
    },
  },
  methods: {
    rateApplicant(data) {
      Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convoAux ? this.convoAux.id : this.convo.id}/evaluate-applicant`, {
        userId: this.selected.user_id,
        ...data,
      }).then(() => {
        this.$toastr.success('', 'Applicant status updated successfully!')
      })
    },
    closeOffcanva() {
      this.$store.dispatch('modals/toggleFormEvaluation', false)
    },
    updateStatus() {
      this.$store.dispatch('users/changeStatus', {
        actions: {
          moveRound: this.status,
        },
        itemIds: [this.selected.user_id],
        convoId: this.convoAux ? this.convoAux.id : this.convo.id,
      }).then(() => {
        this.$store.dispatch('modals/toggleFormEvaluation', false)
        this.$store.dispatch('convo/fetchApplicants', { id: this.convoAux ? this.convoAux.id : this.convo.id, status: this.selected.status.status })
        this.$toastr.success('', 'Actions applied successfully!')
      })
    },
  },
}
</script>
