<!-- eslint-disable vue/no-template-key -->
<template>
  <!-- BEGIN: Content-->
  <div>
    <div
      v-if="!loading"
      id="offcanvasRetrieveApplicant"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-90"
      tabindex="-1"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      aria-labelledby="offcanvasRetrieveApplicantLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasViewFormLabel"
          class="offcanvas-title"
        >
          <small>{{ convo.title }}</small><br><strong>{{ selected.full_name }}</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="d-flex justify-content-sm-end buttons-print">
          <a
            class="me-2 text-dark"
            @click="toggleAllSections(false)"
          ><i data-feather="maximize-2" /> Open all</a>
          <a
            class="text-dark"
            @click="toggleAllSections(true)"
          ><i data-feather="minimize-2" /> Close all</a>
        </div>
        <div class="row">
          <div class="col">
            <PreviewSection
              v-for="(section, index) in sections"
              :key="section.id"
              :section="section"
              :user-id="selected.user_id"
              :index="(index + 1)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>

  <!-- END: Content-->
</template>
<script>
import { mapGetters } from 'vuex'
import PreviewSection from '@/views/convos/partials/preview/PreviewSection.vue'

export default {
  components: {
    PreviewSection,
  },
  props: {
    selected: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/formData',
      convo: 'convo/convo',
      sections: 'convo/sections',
    }),
  },
  watch: {
    async selected() {
      if (this.selected && this.convo.id) {
        this.loading = true
        await this.$store.dispatch('convo/fetchUserSections', [this.convo.id, this.selected.user_id])
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
        this.loading = false
      }
    },
  },
  methods: {
    toggleAllSections(closed) {
      this.$store.dispatch('preview/toggleSections', closed)
      if (!closed) {
        this.$store.dispatch('preview/loadAllGroups')
      }
    },
    closeOffcanva() {
      this.$store.dispatch('modals/toggleFormData', false)
    },
  },
}
</script>
