<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              v-for="parameter in headers"
              :key="parameter.id"
            >
              {{ parameter.name }}
            </th>
            <th v-if="status && mail">
              Status
            </th>
            <th class="th-table-actions">
              {{ convo.open_applicants ? 'Actions' : '' }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="index in entriesNumber"
            :key="index"
          >
            <td
              v-for="parameter in parameters"
              :key="parameter.id"
            >
              <span v-html="getAnswer(parameter, index)"></span>
            </td>

            <td v-if="status && mail">
              <span
                v-if="status && getStatus(status.status, index) != 1"
                class="text-danger"
              >
                Pending letter
              </span>
              <span
                v-else
                class="text-success"
              >
                Letter received
              </span>
            </td>

            <td class="table-actions">
              <div v-if="!status || getStatus(status.status, index) != 1 || (loggedUser && loggedUser.roles && loggedUser.roles.includes('super-admin'))">
                <span
                  v-if="mail"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit "
                >
                  <a
                    title="Copy link"
                    class="btn btn-xl"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasAddEducation"
                    aria-controls="offcanvasAddEducation"
                    @click="copyLink(index), copyClipboard()"
                  ><i data-feather="copy"></i></a>
                </span>

                <span
                  v-if="convo.open_applicants"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit "
                >
                  <a
                    href=""
                    title="edit"
                    class="btn btn-xl"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasAddEducation"
                    aria-controls="offcanvasAddEducation"
                    @click="openModal(index - 1)"
                    @click.prevent
                  ><i data-feather="edit-3"></i></a>
                </span>
                <span
                  v-if="convo.open_applicants"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                >
                  <button
                    id="confirm-text"
                    type="button"
                    class="btn"
                    @click="deleteAnswer(index - 1)"
                  >
                    <i data-feather="trash-2"></i>
                  </button>
                </span>
                <span
                  v-if="mail && loggedUser && loggedUser.roles && loggedUser.roles.includes('super-admin')"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit "
                >
                  <a
                    title="See letter"
                    class="btn btn-xl"
                    @click="checkLetter(index)"
                  ><i data-feather="eye"></i></a>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="convo.open_applicants && entriesNumber < 4"
      class="card-body"
    >
      <button
        class="btn btn-dark btn-sm"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasAddEducation"
        aria-controls="offcanvasAddEducation"
        @click="openModal(null)"
        @click.prevent
      >
        <i data-feather="plus"></i> Add record
      </button>
    </div>
    <ListModal
      :show="showModal"
      :button="showButton"
      :parameters="headers"
      :index="selectedIndex"
      :group="group"
      :section-id="sectionId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as clipboard from 'clipboard-polyfill'
import Vue from 'vue'
import ListModal from './modals/ListModal.vue'

export default {
  components: {
    ListModal,
  },
  props: {
    groupId: { type: String, required: true },
    sectionId: { type: Number, required: true },
    headers: { type: [Array, Object], required: true },
    mail: { type: Boolean, required: true },
    status: { type: Object, required: false, default: null },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      entriesNumber: 0,
      showModal: false,
      selectedIndex: 0,
      showButton: false,
      ercs: [],
      link: null,
    }
  },
  computed: {
    ...mapGetters({
      group: 'convoGroup/group',
      newStatus: 'convoGroup/status',
      payload: 'convoSection/payload',
      loggedUser: 'auth/admin',
      parameters: 'convoGroup/parameters',
      convo: 'convo/convo',
      requiredFields: 'convoSection/requiredFields',
      filledRequiredFields: 'convoSection/filledRequiredFields',
    }),
  },
  watch: {
    // whenever question changes, this function will run
    parameters() {
      this.getTotalParameterLength()
    },
  },
  mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 1500)

    this.fetchGroup()
    this.getTotalParameterLength()
  },
  methods: {
    checkLetter(index) {
      window.open(`${Vue.prototype.$groupUrl}/parameter/check-letter/${this.userId}/${this.$route.params.convo_id ?? this.convo.id}/${this.groupId}/${this.sectionId}/${index}?token=${localStorage.getItem('accessTokenAdmin')}`, '_blank')
    },
    fetchGroup() {
      this.$store.dispatch('convoGroup/fetchGroup', [this.groupId])
      this.$store.dispatch('convoGroup/fetchParameters', [this.groupId, this.$route.params.convo_id ?? this.convo.id, this.sectionId, this.userId ?? null])
    },
    getTotalParameterLength() {
      if (this.parameters[0] && this.parameters[0].answer && this.parameters[0].answer.group_id == this.groupId) {
        let maxLength = 0

        this.parameters.forEach(parameter => {
          try {
            if (parameter.answer && parameter.answer.group_id == this.groupId) {
              const { length } = Object.keys(JSON.parse(parameter.answer.value))
              if (length > maxLength) maxLength = length
            }
          } catch (e) {}
        })

        this.entriesNumber = maxLength
      }
    },
    getStatus(status, index) {
      return JSON.parse(status)[index - 1]
    },
    openModal(index) {
      if (!index && index !== 0) {
        this.selectedIndex = this.entriesNumber + 1
        this.showButton = true
      } else {
        this.selectedIndex = index + 1
        this.showButton = false
      }

      this.showModal = !this.showModal
    },
    async deleteAnswer(index) {
      if (this.convo.open_applicants) {
        await this.$store.dispatch('convoGroup/deleteAnswer', [this.groupId, this.$route.params.convo_id, this.sectionId, index])
        this.fetchGroup()

        this.status = this.newStatus

        if ((100 / this.requiredFields) * (this.filledRequiredFields - 1) >= 0) {
          this.$store.dispatch('convoSection/calculatePercentage', [0, -1])
        }
      }
    },
    getAnswer(parameter, index) {
      if (parameter.answer && parameter.answer.value && parameter.answer.group_id == this.groupId) {
        if (parameter.type.name === 'select' || parameter.type.name === 'radio' || (parameter.type.name === 'combo-box' && Number.isInteger(JSON.parse(parameter.answer.value)[index - 1]))) {
          let name = ''
          parameter.values.forEach(item => {
            if (item.id == JSON.parse(parameter.answer.value)[index - 1]) {
              name = item.default_value
            }
          })

          return name
        }

        if (parameter.type.name === 'erc') {
          const father = parameter.values.find(x => x.id === JSON.parse(parameter.answer.value)[index - 1][0])
          const child = father.childrens.find(x => x.id === JSON.parse(parameter.answer.value)[index - 1][1])
          return `
            <p class="mb-0"><strong>${father.default_value ?? '-'}</strong></p>
            <p class="mb-0">${child ? child.name : '-'}</p>
            <p class="mb-0">${child && child.childrens.find(x => x.id === JSON.parse(parameter.answer.value)[index - 1][2]) ? child.childrens.find(x => x.id === JSON.parse(parameter.answer.value)[index - 1][2]).name : '-'}</p>
          `
        }

        return JSON.parse(parameter.answer.value)[index - 1]
      }
      return null
    },
    async copyClipboard() {
      setTimeout(() => {
        clipboard.writeText(this.link).then(
          () => {
            this.$toastr.success('', 'Copied to clipboard')
          },
          () => {
            this.$toastr.error('', 'Failed to clipboard')
          },
        )
      }, 800)
    },
    async copyLink(index) {
      await this.$store.dispatch('convoSection/getInstitutionUrl', {
        groupId: this.groupId,
        convoId: this.$route.params.convo_id ?? this.convo.id,
        sectionId: this.sectionId,
        userId: this.userId,
        index: (index - 1),
      })

      const urlPayload = this.$router.resolve({
        name: 'front.senior-call-upload-parameter-file',
        params: {
          payload: this.payload,
        },
      })

      this.link = new URL(urlPayload.href, window.location.origin).href
      // navigator.clipboard.writeText(new URL(urlPayload.href, window.location.origin).href)
      // this.$toastr.success('', 'Copied to clipboard1')
    },
  },
}
</script>
